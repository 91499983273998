import React from 'react'
import { NavLink } from 'react-router-dom'
import NavLinkCustom from './NavLinkCustom'
import { useLanguage, useLanguageUpdate } from '../context/LanguageContext'

const Menu = ({ isMenuDisplayed, setMenu }) => {
    const language = useLanguage()
    const changeLanguage = useLanguageUpdate()

    return (
        <nav className={isMenuDisplayed ? 'menu hidden' : 'menu show'}>
            <NavLinkCustom to='/expositions/personnelles' activeUrl='/expositions' onClickFn={ setMenu }>{language === 'fr' ? 'EXPOSITIONS' : 'EXHIBITIONS'}</NavLinkCustom>
            <NavLinkCustom to='/oeuvres/peintures' activeUrl='/oeuvres' onClickFn={ setMenu }>{language === 'fr' ? 'OEUVRES' : 'WORKS'}</NavLinkCustom>
            <NavLink to='/biographie' onClick={ setMenu }>{language === 'fr' ? 'BIOGRAPHIE' : 'BIOGRAPHY'}</NavLink>
            <NavLink to='/contact' onClick={ setMenu }>CONTACT</NavLink>
            <div className='language'>
                <button className={language === 'fr' ? 'active-language' : 'inactive-language'} onClick={() => changeLanguage('fr')}>FR</button>
                <p> /  </p>
                <button className={language === 'en' ? 'active-language' : 'inactive-language'} onClick={() => changeLanguage('en')}>EN</button>
            </div>
        </nav>
    )
}

export default Menu