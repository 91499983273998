import { useMatch, NavLink } from "react-router-dom";

const NavLinkCustom = ({ to, children, activeUrl, onClickFn }) => {
  const match = useMatch(activeUrl + "/*");

  return (
    <NavLink
      to={to}
      className={match ? "active" : ""}
      onClick={ onClickFn }
    >
      {children}
    </NavLink>
  );
};

export default NavLinkCustom
