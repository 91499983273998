import React from 'react'
import NewsletterForm from '../components/NewsletterForm'
import { useLanguage } from '../context/LanguageContext'

const ContactPage = () => {
    const language = useLanguage()
    return (
        <div className='contact-container'>
            <div className='content'>
                {language === 'fr' ? 
                    <p>Pour me contacter, c'est ici : </p>
                    :
                    <p>You can contact me here : </p>
                }
                <a style={{fontWeight: 300, textDecoration: "underline"}} href='mailto:jonathaninizan@protonmail.com'>jonathaninizan@protonmail.com</a>
            </div>

            <div className='content'>
                {language === 'fr' ? 
                    <p>Si vous souhaitez recevoir ma newsletter, vous informant des expositions et nouvelles de l’atelier, veuillez me laisser votre adresse mail ici :</p>
                    :
                    <p>If you wish to receive my newsletter, informing you about exhibitions and news of the studio, please leave your email adress here:</p>
                }
                <NewsletterForm />
            </div>
            <div className='content'>
                {language === 'fr' ? 
                    <>
                    <p style={{fontWeight: "500"}}>Toute reproduction ou représentation d’une œuvre de Jonathan Inizan doit être accompagnée
                    des mentions suivantes :</p>
                    <p>© Adagp, Paris, 2024</p>
                    
                    <p></p>
                    </>
                    :
                    <>
                    <p style={{fontWeight: "500"}}>Any reproduction or representation of a work by Jonathan Inizan must be accompanied
                    of the following mentions :</p>
                    <p>© Adagp, Paris, 2024</p>
                    </>
                }
            </div>
            <div className='content'>
                {language === 'fr' ?
                <p>Pour connaître les modalités d’utilisation des œuvres c’est ici :</p>
                :
                <p>To find out how to use the works, click here :</p>
                }
                <a style={{fontWeight: 300, textDecoration: "underline"}} href='https://www.adagp.fr/fr/identities/jonathan-inizan-888684' target="_blank" rel="noopener noreferrer">www.adagp.fr</a>
            </div>
        </div>
    )
}

export default ContactPage