import React from 'react'
import { useLanguage } from '../context/LanguageContext'

const BiographyPage = () => {
    const language = useLanguage()

    return (<>
       {language === 'fr' ?
            <div className='biography-container'>
                <p>Peintre autodidacte, Inizan commence la peinture vers 15 ans, expérimente l’huile et le dessin avec
                un ami. Au sortir du lycée, il s’orientera vers la musicologie et l’histoire de l’art mais c’est plutôt
                l’école buissonnière de la peinture et de la musique improvisée qui petit à petit s’imposera comme
                le seul outil capable de donner une direction claire à ses visions intérieures.</p>
                <p className='biography-content'> Cette école
                buissonnière devint un art de vivre, et tout s’organisant autour (l’atelier, le salon de musique, les
                toiles qui s’amoncellent, les tubes de peinture et les livres qui s’empilent) c’est la vision intérieure
                qui devient petit à petit réalité. Les expérimentations de la matière, à la manière d’un potier, les
                élaborations graphiques, les recherches de surfaces, textures, les sujets, les abandons de sujets, les
                errances, les doutes et les jubilations, finalement les révélations lumineuses, sont inscrites sur les
                murs, comme si nous avions affaire à des aventures contées. Et il reste des espaces libres.</p>
                <img className='autoportrait' src='autoportrait-70l90h-2023-Inizan©AdagpParis2024.jpg' alt='autoportrait'></img>
                <p className='biography-content'>Ma peinture est guidée par des émotions « qui tiennent le mur » et non un sentiment, comme le dit
                Bonnard. Une émotion, suffisamment forte, suffisamment prégnante pour qu’elle résiste à la raison,
                et suffisamment belle, pour qu’il soit intéressant de la peindre. Le reste est contextuel, et le reste est
                de la culture.</p>
                <p className='biography-content'>« J’ai toujours exposé des artistes que je n’avais pas cherchés. Un jour, j’ai vu un tableau d’Inizan
                qui m’a ému. Ce qui m’a séduit dans son travail, c’est la matière en équilibre et la lumière qui s’en
                dégage. »</p>
                <p className='biography-author'>Christian Njiensi</p>
            </div>
        :
            <div className='biography-container'>
                <p>Inizan starts to paint as a self-taught painter around the age of 15, and experiments oil painting and drawing with a friend. 
                    At the end of high school, he turns toward musicology and the history of art, but the only tool capable to give a clear direction 
                    to his inner visions is more about play truant and improvised music.</p>
                <p className='biography-content'> Play truant will become a way of life, and everything is organized around - 
                the studio, the music room, the paintings, paint tubes and books that pile up ; and then, step by step, the inner vision becomes reality. 
                Experiments with matter, in the manner of a potter, graphic elaborations, surface researches, textures, subjects, subjects abandonments, 
                wanders, doubts and jubilations, and then, finaly, the luminous revelations are written on the walls, as if we were dealing with adventures 
                that are taled to us. And there are still free spaces.</p>
                <img className='autoportrait' src='autoportrait-70l90h-2023-Inizan©AdagpParis2024.jpg' alt='autoportrait'></img>
                <p className='biography-content'>My painting is guided not by an emotion, as says Bonnard, but by emotions wich "hold the wall". 
                An emotion, strong enough, sufficiently significant that it resists reason, and beautiful enough to make it interesting to paint. 
                Everything else is contextual, everything else is cultured. </p>
                <p className='biography-content'>« I have always exhibited artists that I had not sought out. One day, I saw a painting by Inizan which moved me. 
                What appealed to me in his work was the material in balance and the light that emerges. »</p>
                <p className='biography-author'>Christian Njiensi</p>
            </div>
       } 
    </>
    )
}

export default BiographyPage