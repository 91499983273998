import React, { useState } from 'react';
import Header from './Header';
import Menu from './Menu';
import { Outlet } from "react-router-dom";

const Root = () => {
    const [isMenuDisplayed, setIsMenuDisplayed] = useState(true)
    const [hamburgerOpened, setHamburgerOpened] = useState(false)
    // const [windowSize, setWindowSize] = useState(window.screen.width)

    // console.log(windowSize)
    const handleClick = () => {
        setIsMenuDisplayed(!isMenuDisplayed)
        setHamburgerOpened(!hamburgerOpened)
    }
    const handleRightClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        return
    }

    return (
        <div className='root-container' onContextMenu={handleRightClick}>
            <div className='hamburger-bar'>
                <button onClick={() => handleClick()} className={!hamburgerOpened ? 'hamburger close' : 'hamburger open'}></button>
            </div>
            <Header />
            <Menu isMenuDisplayed={ isMenuDisplayed } setMenu={ handleClick }/>
            <Outlet />
        </div>
    )
}

export default Root