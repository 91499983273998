import { useState } from 'react'
import React from 'react'
import { useLanguage } from '../context/LanguageContext'

const NewsletterForm = () => {
    const language = useLanguage()
    const [email, setEmail] = useState('')
    const [error, setError] = useState(null)
    const [validation, setValidation] = useState(null)
 
    function isValidEmail(email) {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
    }

    const handleEmailInputChange = (inputEmail) => {
        setEmail(inputEmail)
        setError(null)
        setValidation(null)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!isValidEmail(email)) {
            setError(language === 'fr' ? 'Attention, cette adresse mail n\'est pas valide.' : 'Warning, this email is invalid.');
            console.log(error)
        } else {
            setError(null);
            setValidation(language === 'fr' ? 'Vous êtes maintenant inscrit à la newsletter.' : 'You are registered.')
            fetch(`${process.env.REACT_APP_BACKEND_URL}/api/newsletter`, {
                method: 'POST',
                body: JSON.stringify({ email }),
                headers: {
                    'Content-Type': 'application/json'
                },
            })
            setEmail('')
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <input value={email} onChange={(e) => handleEmailInputChange(e.target.value)}></input>
                <button type='submit'>{language === "fr" ? 'ENVOYER' : 'SEND'}</button>
            </form>
            {error && <h2 style={{'color': 'red'}}>{ error }</h2>}
            {validation && <h2 style={{'color': 'green'}}>{ validation }</h2>}
        </div>
    )
}

export default NewsletterForm