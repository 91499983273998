import React, { useState, useEffect } from 'react'
import ListOeuvre from '../components/ListOeuvre'
import OeuvresMenu from '../components/OeuvresMenu'
import { useLanguage } from '../context/LanguageContext'

const PeinturesListPage = () => {
    const language = useLanguage()
    const [peintures, setPeintures] = useState([])
    const [sortCol, setSortCol] = useState("annee")
    const [order, setOrder] = useState("DSC")

    useEffect(() => {
        getPeintures()
    }, [])

    const sorting = (col) => {
        setSortCol(col)
        if (order === 'ASC') {
            const sorted = [...peintures].sort((a, b) => a[col] < b[col] ? 1 : -1);
            setPeintures(sorted)
            setOrder('DSC')
        } else if (order === 'DSC') {
            const sorted = [...peintures].sort((a, b) => a[col] > b[col] ? 1 : -1);
            setPeintures(sorted)
            setOrder('ASC')
        }

    }


    const getPeintures = async () => {
        let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/works/paintings/`)
        let data = await response.json()
        setPeintures(data.sort((a, b) => a.annee < b.annee ? 1 : -1))
    }

    return (
        <div className='container'>
            <OeuvresMenu />
            <table className='works-list'>
                <thead>
                    <tr>
                        <th onClick={()=>sorting(language === 'fr' ? 'titre' : 'title')} style={{"minWidth" : "250px", 'cursor': 'pointer'}}>
                            {language === 'fr' ? 'TITRE' : 'TITLE'}
                            {(sortCol === 'titre' || sortCol === 'title') && order === 'DSC' ? 
                                <button className='up'></button> 
                                : 
                                <button className='down'></button>
                            }
                        </th>
                        <th onClick={()=>sorting('annee')} style={{'cursor': 'pointer'}}>
                            DATE
                            {sortCol === 'annee' && order === 'DSC' ? 
                                <button className='down'></button> 
                                : 
                                <button className='up'></button>
                            }
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {peintures.map((peinture, index) => (
                        <ListOeuvre key={index} oeuvre={peinture} path="peintures" />
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default PeinturesListPage