import React, { useState, useEffect } from 'react'
import WorksView from '../components/WorksView'

const RandomPeinturePage = () => {

    let [randomPeinture, setRandomPeinture] = useState([])

    useEffect(() => {
        getRandomPeinture()
    }, [])

    let getRandomPeinture = async () => {
        try {
            let response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/works/random-painting/`)
            let data = await response.json()
            setRandomPeinture(data)
        } catch(e) {
            console.log(e)
        }
    }

    return (
        <WorksView oeuvre={randomPeinture}/>
    )
}

export default RandomPeinturePage